.header {
    background-color: #222;
    color: #00ff00;
    text-align: center;
    padding: 2rem;
  }
  
  .header h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  
  .header p {
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
  }