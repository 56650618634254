.supportUs {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .intro {
    font-size: 1.2rem;
    color: #00ff00;
    margin-bottom: 2rem;
  }
  
  .fundingGoal, .rewards, .cta {
    background-color: #222;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .fundingGoal h2, .rewards h2, .cta h2 {
    color: #00ff00;
    margin-top: 0;
  }
  
  .progressBar {
    width: 100%;
    height: 20px;
    background-color: #333;
    border-radius: 10px;
    overflow: hidden;
    margin: 1rem 0;
  }
  
  .progress {
    height: 100%;
    background-color: #00ff00;
  }
  
  .reward {
    background-color: #333;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .reward h3 {
    color: #00ff00;
    margin-top: 0;
  }
  
  .ctaButton {
    display: inline-block;
    background-color: #00ff00;
    color: #000;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .ctaButton:hover {
    background-color: #00cc00;
  }