.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .intro {
    font-size: 1.2rem;
    color: #00ff00;
    margin-bottom: 2rem;
  }
  
  .mission, .team, .values {
    background-color: #222;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .mission h2, .team h2, .values h2 {
    color: #00ff00;
    margin-top: 0;
  }
  
  .values ul {
    padding-left: 1.5rem;
  }
  
  .values li {
    margin-bottom: 0.5rem;
  }