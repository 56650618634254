.home {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .tagline {
    font-size: 1.2rem;
    color: #00ff00;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3rem;
  }
  
  .feature {
    flex-basis: calc(33.333% - 2rem);
    background-color: #222;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    transition: transform 0.3s ease;
  }
  
  .feature:hover {
    transform: translateY(-5px);
  }
  
  .feature h3 {
    color: #00ff00;
    margin-top: 0;
  }
  
  @media (max-width: 768px) {
    .feature {
      flex-basis: 100%;
    }
  }