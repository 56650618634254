.countdown {
    text-align: center;
    margin: 2rem 0;
  }
  
  .timer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  
  .timer div {
    background-color: #333;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .timer span {
    display: block;
    font-size: 0.8rem;
    color: #00ff00;
  }