.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .nav {
    background-color: #333;
    padding: 1rem;
  }
  
  .nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .nav li {
    margin: 0 1rem;
  }
  
  .nav a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .nav a:hover {
    color: #00ff00;
  }
  
  .main {
    flex-grow: 1;
    padding: 2rem;
  }