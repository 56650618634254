.contact {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .form {
    background-color: #222;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #00ff00;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
  }
  
  .formGroup textarea {
    height: 150px;
  }
  
  .submitButton {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #00cc00;
  }
  
  .contactInfo {
    background-color: #222;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .contactInfo h2 {
    color: #00ff00;
    margin-top: 0;
  }