/* Signup.module.css */
.signup {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #222;
    border-radius: 8px;
    color: #fff;
}

.formGroup {
    margin-bottom: 1rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #00ff00;
}

.formGroup input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #444;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
}

.submitButton {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.submitButton:hover {
    background-color: #00cc00;
}

.signupText {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: #00ff00;
}

.signupText a {
    color: #00ff00;
    text-decoration: underline;
}
