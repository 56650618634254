.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login {
  background-color: #222;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  width: 400px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.tagline {
  color: #aaa;
  margin-bottom: 20px;
}

.submitButton {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submitButton:disabled {
  background-color: #ccc;
}

.errorMessage {
  color: red;
  margin-top: 15px;
}
